<template>
  <y-card v-if="getEvent?.id">

    <y-card-title>
      {{ getEvent.name }}
      <y-card-tabs :tabs="getTabs" />
    </y-card-title>

    <div class="relative overflow-hidden">
      <router-view v-slot="{ Component }">
        <transition
          enter-from-class="translate-x-12 opacity-0"
          leave-active-class="absolute w-full top-0"
          leave-to-class="-translate-x-12 opacity-0">
          <div
            class="transform transition-all duration-300"
            :key="$route.fullPath">
            <component :is="Component" />
          </div>
        </transition>
      </router-view>
    </div>

  </y-card>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import YCard from '@/UI/YCard.vue';
import YCardTitle from '@/UI/YCardTitle.vue';
import YCardTabs from '@/UI/YCardTabs.vue';

import YIconPodium from '@/UI/icons/YIconPodium.vue';
import YIconUsers from '@/UI/icons/YIconUsers.vue';
import YIconClipboardList from '@/UI/icons/YIconClipboardList.vue';
import YIconCalendar from '@/UI/icons/YIconCalendar.vue';

export default {

  components: {
    YCard,
    YCardTitle,
    YCardTabs,
    YIconPodium,
    YIconUsers,
    YIconClipboardList,
  },

  setup() {
    const route = useRoute();
    const store = useStore();

    const eventId = computed(() => route.params?.id);
    store.dispatch('getEventById', eventId.value);
    const getEvent = computed(() => store.getters.getEvent);

    const getTabs = computed(() => {
      const params = { id: eventId.value };
      return [
        {
          icon: YIconPodium,
          route: { name: 'EventRanking', params },
        },
        {
          icon: YIconUsers,
          route: { name: 'EventPlayers', params },
        },
        {
          icon: YIconClipboardList,
          route: { name: 'EventGames', params },
        },
        ...getEvent.value.seasonality ? [{
          icon: YIconCalendar,
          route: { name: 'EventSeasons', params },
        }] : [],
      ];
    });

    return {
      getEvent,
      getTabs,
    };
  },

};
</script>
