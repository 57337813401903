<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 298.334 298.334" fill="currentColor">
    <path d="M183.167,61.394h-68c-5.522,0-10,4.478-10,10V226.94c0,5.522,4.478,10,10,10h68c5.522,0,10-4.478,10-10V71.394
      C193.167,65.871,188.689,61.394,183.167,61.394z" />
    <path d="M75.667,108.514H10c-5.522,0-10,4.478-10,10V226.94c0,5.522,4.478,10,10,10h65.667c5.522,0,10-4.478,10-10V118.514
      C85.667,112.991,81.189,108.514,75.667,108.514z" />
    <path d="M288.334,135.607h-66.667c-5.522,0-10,4.478-10,10v81.333c0,5.522,4.478,10,10,10h66.667c5.522,0,10-4.478,10-10v-81.333
      C298.334,140.085,293.856,135.607,288.334,135.607z" />
  </svg>
</template>
